import React, { useEffect } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import img0 from '../../assets/static/products_01.png'
import img1 from '../../assets/static/home_01.png'
import img2 from '../../assets/static/home_02.png'
import img3 from '../../assets/static/home_03.png'
import img4 from '../../assets/static/home_04.png'
import img5 from '../../assets/static/home_05.png'
import { BsCircleFill } from 'react-icons/bs'

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <Section id="products">
      <Title>
        <Icon />
        Mezcal de Boca en Boca
      </Title>
      <ImageContainer data-aos="fade-left" data-aos-delay="100">
        <Image src={img0} alt="" />
      </ImageContainer>
      <Card data-aos="fade-right" data-aos-delay="100">
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
          do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit
          esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia
          deserunt mollit anim id est laborum.
        </Paragraph>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
          do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit
          esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia
          deserunt mollit anim id est laborum.
        </Paragraph>
      </Card>

      <Card2 data-aos="fade-right" data-aos-delay="100">
        <Subtitle>Tobalá</Subtitle>
        <Paragraph2>
          Tobalá es un tipo de maguey del cual se elabora un
          destilado, "toba" quiere decir maguey y "la" significa
          caliente o aromático, es bien conocido como "El Rey de los
          Mezcales", no es fácil de encontrar ese tipo de maguey
          silvestre que se ubica en las montañas a menudo en
          acantilados rocosos y laderas con difícil acceso. Además, su
          tamaño es muy pequeño y eso implica que para poder producir
          el mezcal se necesitará una gran cantidad de magueyes para
          poder elaborar ese mezcal especial. Es originario de
          Municipio de Villa Sola de Vega, Oaxaca, considerado como la
          cuna del Mezcal Tobalá.
        </Paragraph2>
      </Card2>
      <ImageContainer data-aos="fade-left" data-aos-delay="100">
        <Image src={img1} alt="" />
      </ImageContainer>
      <ImageContainer data-aos="fade-left" data-aos-delay="100">
        <Image src={img2} alt="" />
      </ImageContainer>
      <Card2 data-aos="fade-right" data-aos-delay="100">
        <Subitle2>Espadín</Subitle2>
        <Paragraph2>
          Esta variedad toma el nombre del latín “angusti” que
          significa estrecho, la cual describe la forma de sus hojas
          precisamente por ser largas y estrechas. Actualmente, el
          agave espadín también es llamado de otras formas como:
          vivípara, mezcalero, maguey de campo, entre otros. Es la
          variedad de agave más cultivada en Oaxaca y el tiempo que
          transcurre para poder aprovecharse para la producción de
          mezcal es de aproximadamente 8 años. Una textura dulce y
          herbácea, bien equilibrada con notas de manzana dulce,
          frutas maduras y una suave capa ahumada para finalizar.
        </Paragraph2>
      </Card2>
      <Card2 data-aos="fade-right" data-aos-delay="100">
        <Subtitle>Tepeztate</Subtitle>
        <Paragraph2>
          El maguey Tepeztate, es un Agave de la especie Marmorata, su
          nombre se deriva del vocablo “Tepetl” que en nahuatl
          significa montaña o monte, sin duda hace referencia donde se
          puede encontrar este agave, ya que generalmente se le
          encuentra en acantilados en el monte. El Agave Tepextate se
          da en los estados de Oaxaca y Puebla. Se da en alturas que
          van de los 680 a los 1,600 metros sobre el nivel del mar y
          tarda en madurar entre 25 y 35 años.
        </Paragraph2>
      </Card2>
      <ImageContainer data-aos="fade-left" data-aos-delay="100">
        <Image src={img3} alt="" />
      </ImageContainer>
      <ImageContainer data-aos="fade-left" data-aos-delay="100">
        <Image src={img4} alt="" />
      </ImageContainer>
      <Card2 data-aos="fade-right" data-aos-delay="100">
        <Subitle2>Arroqueño</Subitle2>
        <Paragraph2>
          El Mezcal Arroqueño es aquel que está hecho con el hermoso
          Agave Americana variación Oaxacensis también conocido como
          Maguey Arroqueño. Se da en el estado de de Oaxaca a una alta
          altitud de entre 1,500 y 1,800 metros sobre el nivel del
          mar, tomando hasta 20 años en madurar. El Arroqueño es el
          padre genético del Agave Espadín. Su perfil de sabor es
          parecido. La diferencia radica en que el Arroqueño es la
          versión turbo cargada del Espadín. El sabor a fruta es mucho
          más intenso, es más viscoso y te deja un mejor sabor al
          final.
        </Paragraph2>
      </Card2>
      <Card2 data-aos="fade-right" data-aos-delay="100">
        <Subtitle>Cuishe</Subtitle>
        <Paragraph2>
          El nombre científico de la planta de la que se extrae es
          Agave Karwinskii, y se debe al Barón Wilhelm Friedrich von
          Karwinsky, naturalista y geólogo alemán, que vivió en Oaxaca
          de 1827 a 1832. Este agave es endémico, crece solo en
          México, en los estados de Oaxaca y Puebla. La forma y altura
          de la planta varía; puede encontrarse con un tronco alargado
          de hasta cuatro metros con hojas angostas y puntiagudas en
          forma de roseta o gordo y redondeado, al que le llaman
          tripón.
        </Paragraph2>
      </Card2>
      <ImageContainer data-aos="fade-left" data-aos-delay="100">
        <Image src={img5} alt="" />
      </ImageContainer>
    </Section>
  )
}

export default About

const Section = styled.div`
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  overflow: hidden;

  padding: 60px 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Icon = styled(BsCircleFill)`
  font-size: 1rem;
  margin-right: 10px;
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 768px) {
    grid-column: 1 / 3;
  }
`
const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
  @media (max-width: 768px) {
    grid-column: 1 / 3;
  }
`

const Title = styled.h2`
  grid-column: 1 / 3;
  font-weight: 500;
  letter-spacing: -0.01em;
  font-size: 40px;
  line-height: 1.155em;
  margin: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #B79725;
  @media (max-width: 768px) {
    font-size: 37px;
    line-height: 45px;
  }
`

const Image = styled.img`
  object-fit: contain;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Paragraph = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 40px;
  color: #5A5A5A;
`

const Card2 = styled(Card)`
  justify-content: center;
  align-items: center;
`

const Paragraph2 = styled(Paragraph)`
  width: 50%;
  text-align: justify;
  color: #5A5A5A;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Subtitle = styled.h3`
  font-weight: 500;
  letter-spacing: -0.01em;
  font-size: 40px;
  line-height: 1.155em;
  margin: 5px 0;
  text-align: center;
  width: 100%;
  color: #B79725;
  position: relative;
  @media (max-width: 768px) {
    font-size: 37px;
    line-height: 45px;
  }

  &::after {
    content: '';
    position: absolute;
    right: -115%;
    top: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 2px;
    background-color: #B79725;
    @media (max-width: 768px) {
      height: 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 64%;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #B79725;
    @media (max-width: 768px) {
      height: 0;
    }
  }
`

const Subitle2 = styled(Subtitle)`
  &::after {
    right: 15%;
  }

  &::before {
    left: 35%;
  }
`
