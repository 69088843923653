import React, { useState, useEffect } from 'react'
import styles, { ThemeProvider } from 'styled-components'
import Header from '../containers/Header'
import Footer from '../containers/Footer'
import { GlobalStyles } from '../assets/styles/theme'

const Layout = ({ children }) => {
  const [theme, setTheme] = useState('light')
  const isDarkTheme = theme === 'dark'
  const toggleTheme = () => {
    const updatedTheme = isDarkTheme ? 'light' : 'dark'
    setTheme(updatedTheme)
    localStorage.setItem('theme', updatedTheme)
  }

  React.createContext(theme)

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme')
    const prefersDark =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches

    if (savedTheme && ['dark', 'light'].includes(savedTheme)) {
      setTheme(savedTheme)
    } else if (prefersDark) {
      setTheme('dark')
    }
  }, [])

  return (
    <ThemeProvider theme={{ mode: theme }}>
      <GlobalStyles />
      <Header onTheme={toggleTheme} theme={isDarkTheme} />
      <Main>{children}</Main>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout

const Main = styles.main`
  max-width: 100vw;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
`
