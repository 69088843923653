const initialState = {
  form: {},
}

export default function reducerApp(state = initialState, action) {
  switch (action.type) {
    case 'GET_NUMBERS':
      return {
        ...state,
        numbers: action.payload.data,
      }
    case 'SAVE_FORM':
      return {
        ...state,
        form: action.payload,
      }
    default:
      return state
  }
}
