import React, { useEffect } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import img1 from '../../assets/static/about_01.png'
import img2 from '../../assets/static/about_02.png'
import Button from '../../components/Button'
import { HashLink } from 'react-router-hash-link'

const Inicio = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <Section>
      <Container>
        <Info>
          <Title data-aos="fade-left" data-aos-delay="100">
            Elegimos ser mejores y llevar el mejor mezcal a cada
            rincón del mundo.
          </Title>
          <Link to="#products" smooth>
            <Button data-aos="fade-left" data-aos-delay="200">
              Conócenos
            </Button>
          </Link>
        </Info>
        <Image2 data-aos="fade-down" data-aos-delay="400">
          <img src={img1} alt="" />
        </Image2>
        <Image data-aos="fade-down" data-aos-delay="600">
          <img src={img2} alt="" />
        </Image>
      </Container>
      <LoopText>
        <div>No lo bebas, bésalo. No lo bebas, bésalo.</div>
      </LoopText>
    </Section>
  )
}

export default Inicio

const Section = styled.div`
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  border-top: 5px solid #880000;
  display: grid;
  padding: 70px 40px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  box-sizing: border-box;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
    justify-content: center;
  }
`

const Info = styled.div`
  padding-right: 40px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding-bottom: 20px;
  }
`

const Title = styled.h2`
  color: #B79725;
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 63px;
  @media (max-width: 768px) {
    font-size: 37px;
    line-height: 45px;
  }
`

const Image = styled.div`
  img {
    height: 100%;
    object-fit: contain;
    @media (max-width: 768px) {
      max-height: 300px;
      max-width: 100%;
    }
  }
`

const Image2 = styled.div`
  img {
    width: 100%;
    object-fit: contain;
    @media (max-width: 768px) {
      max-height: 300px;
      max-width: 100%;
    }
  }
`

const LoopText = styled.div`
  height: 200px;
  overflow: hidden;
  line-height: 200px;
  position: relative;
  & > div {
    top: 0;
    left: 100%;
    width: max-content;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    color: #fff;
    font-size: 200px;
    animation: marquee 30s linear infinite;
    stroke: #B79725;
    -webkit-text-stroke: 2px #B79725; /* width and color */
    @media (max-width: 768px) {
      font-size: 100px;
      animation: marquee 10s linear infinite;
    }
  }

  @media (max-width: 768px) {
    height: 100px;
    line-height: 100px;
    box-sizing: border-box;
  }

  @keyframes marquee {
    0% {
      left: 100%;
    }
    100% {
      left: -100%;
    }
  }
`

const Link = styled(HashLink)`
  text-decoration: none;
`
