import React from 'react'
import Inicio from './Home'
import Nosotros from './Nosotros'
import About from './About'
import Products from './Products'

const Home = () => {
  return (
    <>
      <Inicio />
      <Nosotros />
      <About />
      <Products />
    </>
  )
}

export default Home
