import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import styled from 'styled-components'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

SwiperCore.use([Autoplay])

const Carrousel = ({ data }) => {
  return (
    <>
      <Swiper
        pagination={true}
        modules={[Pagination]}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        loop
        className="mySwiper"
      >
        {data.length > 0
          ? data.map(item => (
              <Slide key={item.id}>
                <h1>{item.title}</h1>
                <img src={item.image} alt="" />
              </Slide>
            ))
          : null}
      </Swiper>
    </>
  )
}

export default Carrousel

const Slide = styled(SwiperSlide)`
  position: relative;

  > h1 {
    font-weight: 500;
    letter-spacing: -0.01em;
    font-size: 40px;
    color: #B79725;
    line-height: 1.155em;
    position: absolute;
    top: 7%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    z-index: 1;
    @media (max-width: 768px) {
      display: none;
    }
  }

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 70vh;
    @media (max-width: 768px) {
      height: 50vh;
      width: 100vw;
      object-fit: cover;
    }
  }
`
