import { createGlobalStyle } from 'styled-components'
export const GlobalStyles = createGlobalStyle`
  body {
    transition: all 0.2s ease-in-out;
    font-family: 'DM Sans', sans-serif;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 5%;
  }

  .swiper-pagination-bullet {
    width: 6px !important;
    height: 6px !important;
    background-color: transparent;
    border: 1px solid #880000;
    border-radius: 50%;
    margin-right: 9px !important;
  
  }

  .swiper-pagination-bullet-active {
    background-color: #880000;
  }
  
  
`

export const lightTheme = {
  body: '#fff',
  text: '#000',
  primary: '#7887A8',
  secondary: '#4B556C',
}
export const darkTheme = {
  body: '#fff',
  text: '#000',
  primary: '#7887A8',
  secondary: '#4B556C',
}
