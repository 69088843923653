import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import { BsCircle, BsCircleFill } from 'react-icons/bs'
import { IoReorderTwoOutline } from 'react-icons/io5'
import logo from '../../assets/static/logo.svg'
import Side from '../../components/Side'
const Header = () => {
  const [togleBar, setTogle] = useState(false)
  const [navbar, setNavbar] = useState(false)
  const [side, setSide] = useState(false)
  const [menu, setMenu] = useState([
    {
      id: 1,
      name: 'Inicio',
      link: '/#home',
      active: true,
    },
    {
      id: 2,
      name: 'Nosotros',
      link: '/#nosotros',
      active: false,
    },
    {
      id: 3,
      name: 'Mezcal De Boca en Boca',
      link: '/#products',
      active: false,
    },
    {
      id: 4,
      name: 'Contacto',
      link: '/#contact',
      active: false,
    },
  ])

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
  })

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  const handleMenu = id => {
    setTogle(false)
    const newMenu = menu.map(item => {
      if (item.id === id) {
        return {
          ...item,
          active: true,
        }
      } else {
        return {
          ...item,
          active: false,
        }
      }
    })
    setMenu(newMenu)
  }

  return (
    <>
      <Side show={side} onClick={() => setSide(false)} />
      <HeaderS navbar={navbar}>
        <Menu togleBar={togleBar}>
          <Img src={logo} alt="logo" />
          <Ul togleBar={togleBar}>
            {menu.map(item => (
              <Li key={item.id}>
                <HashLink
                  smooth
                  to={item.link}
                  onClick={() => handleMenu(item.id)}
                >
                  {item.active === true ? (
                    <BsCircleFill />
                  ) : (
                    <BsCircle />
                  )}{' '}
                  {item.name}
                </HashLink>
              </Li>
            ))}
          </Ul>
          <Hamburguer
            togleBar={togleBar}
            onClick={() => setTogle(!togleBar)}
          >
            {togleBar ? <AiOutlineClose /> : <AiOutlineMenu />}
          </Hamburguer>
        </Menu>
        <HamburguesBlack onClick={() => setSide(true)}>
          <HamburgerContainer>
            <IoReorderTwoOutline />
          </HamburgerContainer>
        </HamburguesBlack>
      </HeaderS>
    </>
  )
}

export default Header

const HeaderS = styled.header`
  display: grid;
  grid-template-columns: 1fr 80px;
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  background-color: transparent;
  z-index: 999999;
  position: absolute;
  height: 83px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100vw;
    border-color: transparent;
  }
`

const Menu = styled.div`
  grid-column: 1 / 2;
  z-index: 11;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 100vw;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  max-height: 80px;
  max-width: calc(100vw - 80px);
  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100vw;
    max-width: 100vw;
    padding-right: 0;
  }
`
const Img = styled.img`
  height: 50px;
  object-fit: contain;
  margin-right: 15px;
  @media (max-width: 768px) {
    height: 60px;
  }
`

const Ul = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  list-style: none;
  transition: all 1s ease-out;
  height: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    background: #fff;
    position: absolute;
    margin: 0;
    width: 100vw;
    height: 100vh;
    transition: all 0.5s ease-out;
    flex-direction: column;
    justify-content: center;
    boz-sizing: border-box;
    top: 0;
    left: 0;
    ${props =>
      props.togleBar === false
        ? 'transform: translateX(-100%);'
        : 'transform: translateX(0%);'}
  }
`

const Li = styled.li`
  text-align: center;
  padding: 0 15px;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  & a {
    width: 100%;
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-weight: 100;
    font-weight: 700;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      margin-right: 5px;
      font-size: 0.4rem;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
      color: #000;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    transform: scale3d(0, 1, 1);
    transform-origin: left;
    transition: transform 0.2s;
  }
  &:hover {
    & a {
      color: #fff;
    }
    &::before {
      background-color: #fff;
      transform: scale3d(1, 1, 1);
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 0.3s;
    }
  }
  @media (max-width: 768px) {
    height: 40px;
  }
`

const Hamburguer = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 100;
  display: none;
  @media (max-width: 768px) {
    z-index: 12;
    display: flex;
    color: ${props => (props.togleBar ? '#000' : '#fff')};
    transition: color 0.5s ease-out;
    height: 80px;
    width: 80px;
    justify-content: center;
    align-items: center;
  }
`

const HamburguesBlack = styled.div`
  grid-column: 2 / 3;
  color: #fff;
  width: 80px;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  @media (max-width: 768px) {
    display: none;
  }
`
const HamburgerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: #B79725;
  align-items: center;
  transition: color 0.2s ease-out;
  font-size: 2.5rem;
`
