import React, { useEffect } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { BsCircleFill } from 'react-icons/bs'
import Button from '../../components/Button'
import { FiArrowUpRight } from 'react-icons/fi'
import Carrousel from '../../components/Carrousel'
import img1 from '../../assets/static/home_01.png'
import img2 from '../../assets/static/home_02.png'
import img3 from '../../assets/static/home_03.png'
import img4 from '../../assets/static/home_04.png'
import img5 from '../../assets/static/home_05.png'
import bg from '../../assets/static/home_bg.png'
import { HashLink } from 'react-router-hash-link'

const Inicio = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  const carrouselImages = [
    {
      id: 1,
      image: img1,
      title: 'Tobalá',
    },
    {
      id: 2,
      image: img2,
      title: 'Espadín',
    },
    {
      id: 3,
      image: img3,
      title: 'Tepeztate',
    },
    {
      id: 4,
      image: img4,
      title: 'Arroqueño',
    },
    {
      id: 5,
      image: img5,
      title: 'Cuishe',
    },
  ]

  return (
    <InicioS id="home">
      <Left>
        <Box>
          <Icon /> EXPERTOS EN MEZCAL
        </Box>
        <Title data-aos="fade-left" data-aos-delay="200">
          Producimos el mejor mezcal del País
        </Title>
        <Link to="#nosotros" smooth>
          <Button color="white">
            Más información <FiArrowUpRight />
          </Button>
        </Link>
      </Left>
      <BannerCotainer>
        <Carrousel data={carrouselImages} />
      </BannerCotainer>
    </InicioS>
  )
}

export default Inicio

const InicioS = styled.div`
  background-color: #141619;
  display: grid;
  grid-template-columns: 50vw 50vw;
  color: #fff;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const BannerCotainer = styled.div`
  height: 100vh;
  width: 50vw;
  max-width: 50vw;
  box-sizing: border-box;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
  @media (max-width: 768px) {
    width: 100vw;
    max-width: 100vw;
    height: 50vh;
  }
`

const Title = styled.h1`
  width: 100%;
  font-size: 4.5rem;
  color: #fff;
  text-align: left;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 5rem;
  position: relative;
  background-image: linear-gradient(
      to bottom,
      rgba(136, 0, 0, 0.7),
      rgba(136, 0, 0, 0.7)
    ),
    url(${bg});

  @media (max-width: 768px) {
    padding: 2rem;
    height: 50vh;
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled(BsCircleFill)`
  font-size: 0.3rem;
  margin-right: 5px;
`

const Link = styled(HashLink)`
  text-decoration: none;
`
