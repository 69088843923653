import React from 'react'
import ReactDOM from 'react-dom'
import { Normalize } from 'styled-normalize'
import App from './routes'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducers/index'

const middleware = [thunk]
const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middleware))
)

if (sessionStorage.getItem('token')) {
  store.getState().reducerApp.loggedIn = true
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Normalize />
      <App loggedIn={store.getState().reducerApp.loggedIn} />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
