import React from 'react'
import styled from 'styled-components'

const Button = props => {
  const { children, type = 'button', color } = props
  return (
    <ButtonStyled type={type} color={color} {...props}>
      {children}
    </ButtonStyled>
  )
}

export default Button

const ButtonStyled = styled.button`
  padding: 11px 19px;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  background-color: ${({ color }) => {
    switch (color) {
      case 'white':
        return '#B79725'
      case 'transparent':
        return 'transparent'
      default:
        return '#880000'
    }
  }};

  color: ${({ color }) => {
    switch (color) {
      case 'white':
        return '#000'
      case 'transparent':
        return '#000'
      default:
        return '#fff'
    }
  }};
  transition: background-size 0.3s;
  display: inline-block;
  font-weight: 400;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background-size: 300%;
    color: #ffffff;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    margin-left: 5px;
    font-size: 25px;
  }
`
