import React, { useEffect } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import video from '../../assets/static/video.mp4'
import logo from '../../assets/static/logo.svg'
import { BsCircleFill } from 'react-icons/bs'

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <Section id="nosotros">
      <Title2>
        <Icon />
        Nosotros
      </Title2>
      <Card data-aos="fade-right" data-aos-delay="100">
        <Title>
          Somos una empresa Mexicana dedicada a la producción de
          mezcal artesanal desde 2021.
        </Title>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
          do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit
          esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia
          deserunt mollit anim id est laborum.
        </Paragraph>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
          do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit
          esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia
          deserunt mollit anim id est laborum.
        </Paragraph>
      </Card>
      <Card data-aos="fade-left" data-aos-delay="100">
        <Logo src={logo} alt="" />
        <VideoContainer>
          <Video autoPlay muted controls={false} loop>
            <source src={video} type="video/mp4" />
          </Video>
        </VideoContainer>
      </Card>
    </Section>
  )
}

export default About

const Section = styled.div`
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 40%;
  gap: 50px;
  overflow: hidden;
  background-color: #880000;
  padding: 60px 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Icon = styled(BsCircleFill)`
  font-size: 1rem;
  margin-right: 10px;
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 768px) {
    grid-column: 1 / 3;
  }
`

const Title = styled.h2`
  grid-column: 1 / 3;
  font-weight: 500;
  letter-spacing: -0.01em;
  font-size: 40px;
  line-height: 1.155em;
  margin: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 37px;
    line-height: 45px;
  }
`

const Title2 = styled(Title)`
  color: #b79725;
`

const Logo = styled.img`
  width: 100%;
  margin-bottom: 1rem;
`

const Paragraph = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 40px;
  color: #fff;
`

const VideoContainer = styled.div`
  width: 100%;
  object-fit: cover;

`

const Video = styled.video`
  width: 100%;
  object-fit: cover;
`
