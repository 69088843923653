import React from 'react'
import styled from 'styled-components'
import logo from '../../assets/static/logo.svg'
import { HashLink } from 'react-router-hash-link'

const Footer = () => {
  return (
    <Main id="contact">
      <Container>
        <Icons>
          <Logo src={logo} alt="logo" />
          <p>
            {/* <b>Oficinas</b>: Prolongación de la 15 sur 2317-A Int.
            10-1 C.V <br /> San Pedro Cholula, Puebla, México. CP.
            72060
            <br /> */}
            <b>Fábrica</b>: Oaxaca, México.
          </p>
        </Icons>
        <Rights>
          <a href="mailto:contacto@gccinternational.com">
            contacto@gccinternational.com
          </a>
          <a href="tel:+522222222222">+52 222 222 2222</a>
          <p>
            Todos los derechos reservados GCC INTERNATIONAL S.A. DE
            C.V <br />
            {/* Powered by Zuse Technologies S.A. de C.V. */}
          </p>
        </Rights>
        <Icons2>
          <HashLink smooth to="/#home">
            Inicio
          </HashLink>
          <HashLink smooth to="/#nosotros">
            Nosotros
          </HashLink>
          <HashLink smooth to="/#products">
            Mezcal de Boca en Boca
          </HashLink>
        </Icons2>
      </Container>
    </Main>
  )
}

export default Footer

const Main = styled.footer`
  max-width: 100vw;
  padding: 0 40px 0 40px;
  background-color: #4b556c;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 0 20px 0 20px;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  text-align: center;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Rights = styled.div`
  line-height: 1.7;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  & > a {
    color: #fff;
    text-decoration: none;
    font-size: 25px;
  }
  & > p {
    color: #fff;
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 12px;
  }
`

const Icons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  > p {
    text-align: left;
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    > span {
      font-weight: bold;
    }
  }
  > a {
    color: #fff;
    font-size: 12px;
    margin: 0.5rem;
    text-decoration: none;
    text-align: left;
  }

  /* @media (max-width: 768px) {
    position: relative;
    transform: translateY(100%);
    margin-right: 20px;
  } */
`

const Icons2 = styled(Icons)`
  justify-content: center;
`

const Logo = styled.img`
  height: 50px;
`
